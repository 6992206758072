import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import jquery from "jquery";
import 'jquery-ui';
import Uri from 'jsuri';
import 'jquery-colpick';
import 'datatables.net';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/sortable';
import select2 from 'select2'; // funziona correttamente già così
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui-timepicker-addon';

// Filepond
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType
);

// Import the plugin styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';

window.FilePond = FilePond;

window.$ = jquery;
window.jQuery = jquery;


UIkit.use(Icons);

/**
 * Rende disponibili le librerie nello scope globale.
 * Alternativamente, spostare gli import in app.js
 */
window.UIkit = UIkit;
window.Uri = Uri;
